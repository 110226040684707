import React from "react"
import Layout from "../components/layout"
import {
  HeaderSecondary,
  ContactSection,
  DecorationSection,
  CollabrationSection,
} from "../components/utility"

import { useStaticQuery, graphql } from "gatsby"

export default function Collabration() {
  const data = useStaticQuery(graphql`
    query {
      brand4: file(relativePath: { eq: "product/collaborationProduct.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Product4 = [
    data.brand4.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35))`,
  ].reverse()

  return (
    <Layout>
      <HeaderSecondary
        fluid={Product4}
        title="Business Phone System"
        caption="A full featured phone system that is hosted in the cloud. Make and receive calls via your computer or mobile, or add a smart handset."
      />
      <CollabrationSection />
      <ContactSection />
      <DecorationSection />
    </Layout>
  )
}
